@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "CustomFont";
  src: url('/src/assets/fonts/century-gothic/CenturyGothic.ttf') format('truetype');
}

@layer base {
  body {
    @apply text-lg bg-[#F6F6F6];
    @apply dark:bg-dark; /* Örnek bir dark mode arka plan rengi */
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-dark;
    @apply dark:text-light; /* Başlık renkleri için dark mode renkleri */

  }
  .title,
  .section-title {
    @apply text-3xl md:text-5xl lg:text-6xl xxl:text-7xl mb-6 font-bold;
  }
  .h2 {
    @apply text-2xl md:text-4xl lg:text-5xl xxl:text-6xl font-bold;
  }
  .pretitle {
    @apply text-sm md:text-base lg:text-[18px] text-light mb-4 lg:mb-6 uppercase font-normal;
  }
  .lead {
    @apply text-base md:text-xl text-light font-normal mb-6;
  }
  .link {
    @apply text-sm md:text-base text-dark;
    @apply dark:text-light; /* Link renkleri için dark mode renkleri */

  }
  .section {
    @apply py-3 md:py-8 lg:py-[142px];
  }
  .btn {
    @apply rounded-lg transition flex justify-center items-center;

  }
  .btn-lg {
    @apply h-[60px] px-6 text-base md:text-xl font-normal;
  }
  .btn-md {
    @apply h-[50px] px-[16px] text-base font-normal;
  }
  .btn-sm {
    @apply h-[38px] px-[16px] text-base font-normal;
  }
  .btn-accent {
    @apply text-white bg-accent dark:text-light dark:bg-white hover:bg-accentHover;
  }
  .btn-white {
    @apply text-accent bg-white hover:bg-white/90;
  }
  .btn-outline {
    @apply bg-transparent text-accent border border-accent hover:bg-accent hover:text-white;
  }
  .btn-link {
    @apply text-accent dark:text-white text-lg lg:text-xl;
  }
}
/* styles.css */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-right {
  animation: slideInFromRight 0.3s ease-out forwards;
}

/* styles.css ya da tailwind.config.js içinde */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-top {
  animation: slideInFromTop 0.5s ease-out forwards;
}


*::-webkit-scrollbar {
  display: none;
}

